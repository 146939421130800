import { Box, CircularProgress, InputAdornment, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ActionMenu, { useEntityLinkAction } from "components/ActionMenu";
import { SettingsFormTextArea } from "modules/picasso-ui/form/settingsForm/SettingsFormLayout";
import { MultilineText } from "modules/picasso-ui/present/MultilineText";
import { getItemComments, uploadCommentImageFromBase64 } from "modules/plinzip/api/backlogApi";
import { useTranslation } from "next-i18next";
import { arrayIsEmpty, dynamicSort } from "utils/arrayUtils";
import { formatDateFromSeconds } from "utils/dateUtils";
import { useResolved } from "utils/useResolved";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { submitItemComment } from "modules/plinzip/api/backlogApi"
import ProgressButton from "modules/picasso-ui/form/ProgressButton"
import { useState } from "react";
import { hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder";
import { entityId } from "utils/apiUtils";
import { AssetsUploadBar } from "modules/picasso-ui/form/assets/AssetsUploadBar";
import { notNullNotUndefined } from "utils/objectUtils";
import alerts from "modules/yoio/state/alerts";
import { AssetsView } from "modules/picasso-ui/form/assets/AssetsView";
import { useWallDialog } from "components/dialog/confirm/WallDialog";
import { InterceptClickBoundary } from "modules/picasso-ui/interact/InterceptClickBoundary";

const useStyles = makeStyles((theme)=>({
    userName: {
        fontWeight: '500',
        color: '#3e4960',
    },
    commentRoot: {
        '&:hover $actions': {
            visibility: 'visible',
        },
    },
    commentInput: {
        paddingTop: '13px',
        paddingBottom: '13px',
        alignItems: 'flex-start'
    },
    text: {
        'overflow-wrap': 'anywhere',
        paddingRight: theme.spacing(3),
    },
    date: {
        fontSize: '12px',
        color: theme.palette.text.secondary,
    },
    actions: {
        [theme.breakpoints.up('md')]: {
            visibility: 'hidden',
        }
    },
    commentAdornment: {
        marginTop: '8px',
        color: theme.palette.text.secondary
    },
    commentsEmptyStateText: {
        fontSize: '12px',
    },
}))

export const FeedbackCommentWidget = ({backlogId, itemId, backlogEac}) => {

    const classes = useStyles()

    const { t } = useTranslation(['common', 'vsprio'])

    const wallDialog = useWallDialog()

    const [items, refresh, remove, loading] = useResolved(() => getItemComments(backlogId, itemId).then((data) => data.data));

    const refreshSilent = () => {
        refresh({ silent: true})
    }

    const hasCommentPermission = backlogEac && hasPermissionInEac(backlogEac, 'backlogItemCommentCreate')

    const renderItems = () => {
        
        if (arrayIsEmpty(items)) {
            return <Box pt={3} textAlign="center" color="text.secondary" className={classes.commentsEmptyStateText}><Typography>{t('common:comments.emptyState')}</Typography></Box>
        }
            
        return items.sort(dynamicSort('createdAt')).map((i, idx) => {
            return (
                <Box key={idx} mb={3} display="flex" className={classes.commentRoot}>
                    <Box flexGrow={1}>
                        <Box>
                            <Typography className={classes.userName}>{i.userName || 'Unknown'}</Typography>
                        </Box>
                        <Box color="text.primary">
                            <Typography className={classes.text}><MultilineText>{i.text}</MultilineText></Typography>
                        </Box>
                        <Box pt={1}>
                            {i.assets?.length >= 1 && (
                                <AssetsView assets={i.assets} viewerAllowed={true} />
                            )}
                        </Box>
                    </Box>

                    <Box textAlign="right" display="flex" flexDirection="column">
                        <Box className={classes.actions}>
                            <CommentActionMenu 
                                comment={i}
                                onChangeSuccess={refreshSilent}
                            />
                        </Box>
                        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
                            <Typography className={classes.date}>{formatDateFromSeconds(i.createdAtTs)}</Typography>
                        </Box>
                    </Box>
                </Box>
            )
        })
    }

    const renderNewCommentInput = () =>  <NewCommentInput
        itemId={itemId}
        backlogId={backlogId} 
        onSubmitSuccess={refreshSilent}
    />

    const handleNewCommentClickWhenNoPermission = async () => {
        await wallDialog.getWallDialog({
            title: 'Login',
            message: t('vsprio:portal.feedback.comment.feedbackCommentPublicDisabledHelperText')
                        + "\n" + t('vsprio:portal.permissionOnlyForPortalMembers.helperText'),
        })
    }

    return (
        <>
            <Box pb={3}>
                {loading === true ? 
                    <Box textAlign="center">
                        <CircularProgress />
                    </Box>
                    :
                    <>{renderItems()}</>
                }
            </Box>
            <Box mt={3}>
                {hasCommentPermission ? 
                    renderNewCommentInput()
                    :
                    <InterceptClickBoundary onClick={handleNewCommentClickWhenNoPermission}>
                        {renderNewCommentInput()}
                    </InterceptClickBoundary>
/*                     <Box color="text.secondary">
                        <Typography>
                            {t('vsprio:portal.feedback.comment.feedbackCommentPublicDisabledHelperText')}
                        </Typography>
                    </Box> */
                }
            </Box>
        </>
    )

}

const CommentActionMenu = ({comment, onChangeSuccess}) => {

    const { t } = useTranslation('common')

      const deleteComment = useEntityLinkAction(comment, 'delete', {
        name: t('common:action.delete'),
        confirmation: {
          title: 'Delete comment?',
          confirmButtonText: 'Delete',
        },
        then: onChangeSuccess
      });
    
      const menuItems = {
        actionsOften: []
      };
    
      if (deleteComment) {
        menuItems.actionsOften.push(deleteComment)
      }

      return menuItems.actionsOften.length > 0 ? <ActionMenu items={menuItems}></ActionMenu> : <></>

}

const NewCommentInput = ({backlogId, itemId, onSubmitSuccess}) => {

    const classes = useStyles()

    const [comment, setComment] = useState('')

    const [assets, setAssets] = useState([])

    const [submitting, setSubmitting] = useState(false)

    const handleChange = (e) => {
        setComment(e.target.value)
    }

    const handleAssetsChange = (value) => {
        setAssets(value)
    }

    const handleSubmit = () => {
        setSubmitting(true)

        const commentId = entityId()

        let uploads = assets ? assets.map((a)=>{
            return uploadCommentImageFromBase64(backlogId, itemId, commentId, a.data_url)
        })
        : [] // or no promises
    
        if (uploads?.length > 0) {
            Promise.all(uploads).then((values) => {
                const assetIds = values.map(v=>v.data.assetId)
                return submitCommentItself(commentId, assetIds) //WITH IMAGES
            }).catch((e)=> {
                alerts.error('error uploading attachments. please retry.')
                console.error(e)
                setSubmitting(false)
            })
            return; //VERY IMPORTANT
        }

        //without assets
        submitCommentItself(commentId)

    }

    const submitCommentItself = (commentId, assetIds) => {
        notNullNotUndefined(commentId)

        submitItemComment(backlogId, itemId, {
            commentId: commentId,
            text: comment,
            assetIds
        })
        .then(()=> {
            setComment('')
            setAssets([])
            setSubmitting(false)
            if (onSubmitSuccess) {
                return onSubmitSuccess()
            }
        })
        .catch(()=> {
            setSubmitting(false)
        })
    }

    const renderSubmitBtn = () => {
        return (<ProgressButton 
            color="primary"
            variant="contained"
            loading={submitting}
            disabled={comment.length <= 1 || comment.length > 3000} 
            onClick={handleSubmit}>
            Comment
        </ProgressButton>
        )
    }

    return (
        <>
            <SettingsFormTextArea
                //size="large"
                placeholder='Comment...'
                value={comment}
                onChange={handleChange}
                minHeight='0px'
                InputProps={{
                    className: classes.commentInput,
                    startAdornment: (
                        <InputAdornment position="start" className={classes.commentAdornment}>
                            <ChatBubbleOutlineIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <>
                        </>
                    )
                }}
            />
            <Box pt={1} display="flex" justifyContent="space-between">
                <AssetsUploadBar 
                    assets={assets} 
                    onChange={handleAssetsChange}
                    />
                {assets.length === 0 && <Box>{renderSubmitBtn()}</Box>}
            </Box>
            {assets.length >= 1 && (
                <Box pt={1} textAlign="right">{renderSubmitBtn()}</Box>
            )}
            
        </>
    )

}